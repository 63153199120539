// extracted by mini-css-extract-plugin
export var colLeft = "UserCloudIaasStorageProject_Details__colLeft__OSIIy";
export var colRight = "UserCloudIaasStorageProject_Details__colRight__cp0cn";
export var column = "UserCloudIaasStorageProject_Details__column__wHbyP";
export var columnWrapper = "UserCloudIaasStorageProject_Details__columnWrapper__PCS2K";
export var descriptionContent = "UserCloudIaasStorageProject_Details__descriptionContent__Hedng";
export var descriptionHeading = "UserCloudIaasStorageProject_Details__descriptionHeading__KzMMf";
export var flex = "UserCloudIaasStorageProject_Details__flex__kYtfC";
export var flexColumn = "UserCloudIaasStorageProject_Details__flexColumn__VPepO";
export var gap1 = "UserCloudIaasStorageProject_Details__gap1__NM_JJ";
export var gap2 = "UserCloudIaasStorageProject_Details__gap2__X97qX";
export var gap3 = "UserCloudIaasStorageProject_Details__gap3__c9BHT";
export var gap4 = "UserCloudIaasStorageProject_Details__gap4__IhK0J";
export var gap5 = "UserCloudIaasStorageProject_Details__gap5__pL5E4";
export var lastUpdated = "UserCloudIaasStorageProject_Details__lastUpdated__VPlPt";
export var reviewStatusWarning = "UserCloudIaasStorageProject_Details__reviewStatusWarning__iVU0P";
export var row = "UserCloudIaasStorageProject_Details__row__clmXo";