// extracted by mini-css-extract-plugin
export var cloudHeader = "UserCloudIaasStorageProject__cloudHeader__uo6Ab";
export var column = "UserCloudIaasStorageProject__column__PErqo";
export var container = "UserCloudIaasStorageProject__container__Wt1yP";
export var contentWrapper = "UserCloudIaasStorageProject__contentWrapper__jL3AI";
export var flex = "UserCloudIaasStorageProject__flex__ws8j6";
export var flexColumn = "UserCloudIaasStorageProject__flexColumn__ARYfO";
export var gap1 = "UserCloudIaasStorageProject__gap1__BzhQw";
export var gap2 = "UserCloudIaasStorageProject__gap2__UGjys";
export var gap3 = "UserCloudIaasStorageProject__gap3__a8BAY";
export var gap4 = "UserCloudIaasStorageProject__gap4__OgqWh";
export var gap5 = "UserCloudIaasStorageProject__gap5__irJAY";
export var row = "UserCloudIaasStorageProject__row__iRFQO";
export var tag = "UserCloudIaasStorageProject__tag__r_TQP";