// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../styleguide/components/Paragraph/P.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectEstimatedDates from "../_components/ProjectEstimatedDates/ProjectEstimatedDates.res.js";
import * as ProjectDetailsProviders from "../_components/ProjectDetailsProviders/ProjectDetailsProviders.res.js";
import * as UserProjectDetailsOwner from "../_components/UserProjectDetailsOwner/UserProjectDetailsOwner.res.js";
import * as UserProjectDetailsDocuments from "../_components/UserProjectDetailsDocuments/UserProjectDetailsDocuments.res.js";
import * as UserProjectConciergesInformation from "../_components/UserProjectDetailsContactInformation/UserProjectConciergesInformation.res.js";
import * as UserProjectDetailsContactInformation from "../_components/UserProjectDetailsContactInformation/UserProjectDetailsContactInformation.res.js";
import * as UserCloudIaasStorageProject_DetailsScss from "./UserCloudIaasStorageProject_Details.scss";
import * as UserProjectDetailsBusinessContactInformation from "../_components/UserProjectDetailsContactInformation/UserProjectDetailsBusinessContactInformation.res.js";
import * as UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel from "./UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel.res.js";

var css = UserCloudIaasStorageProject_DetailsScss;

function UserCloudIaasStorageProject_Details(props) {
  var project = props.project;
  var match = project.status;
  var tmp;
  tmp = match === "Review" ? JsxRuntime.jsxs("p", {
          children: [
            "NOTE: Please be advised that this project is in review. ",
            "A member of Datacenters.com will reach out to discuss this project before ",
            "it becomes active."
          ],
          className: Cx.cx([
                css.reviewStatusWarning,
                "mb-4"
              ])
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h2", {
                                      children: "Description",
                                      className: css.descriptionHeading
                                    }),
                                tmp,
                                JsxRuntime.jsx(P.make, {
                                      children: project.description
                                    }),
                                JsxRuntime.jsx(P.make, {
                                      children: "Project Last Updated " + Format(project.updatedAt, "MMM dd, yyyy, h:mm a")
                                    }),
                                JsxRuntime.jsx("h2", {
                                      children: "Required Products",
                                      className: css.descriptionHeading
                                    }),
                                Belt_Array.mapWithIndex(project.cloudIaasStorageConfigurations, (function (index, configuration) {
                                        return JsxRuntime.jsx(UserCloudIaasStorageProject_Details_RequiredProducts_ProductPanel.make, {
                                                    configuration: configuration,
                                                    isExpanded: index === 0
                                                  }, String(index));
                                      })),
                                project.documents.length !== 0 ? JsxRuntime.jsx(UserProjectDetailsDocuments.make, {
                                        documents: project.documents,
                                        projectId: project.id
                                      }) : null
                              ],
                              className: css.colLeft
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(ProjectEstimatedDates.make, {
                                      estimatedDates: props.estimatedDates
                                    }),
                                JsxRuntime.jsx(UserProjectDetailsOwner.make, {
                                      owner: project.owner,
                                      isAgent: project.isAgent
                                    }),
                                project.contacts.length !== 0 ? JsxRuntime.jsx(UserProjectDetailsContactInformation.make, {
                                        contacts: project.contacts
                                      }) : null,
                                JsxRuntime.jsx(UserProjectDetailsBusinessContactInformation.make, {
                                      businessName: project.businessName,
                                      businessEmail: project.businessEmail,
                                      businessPhone: project.businessPhone,
                                      businessContactName: project.businessContactName,
                                      headquartersAddress: project.headquartersAddress
                                    }),
                                JsxRuntime.jsx(UserProjectConciergesInformation.make, {
                                      concierges: props.concierges
                                    })
                              ],
                              className: css.colRight
                            })
                      ],
                      className: css.columnWrapper
                    }),
                JsxRuntime.jsx(ProjectDetailsProviders.make, {
                      projectId: project.id,
                      userRole: props.userRole,
                      providers: project.providers
                    })
              ]
            });
}

var make = UserCloudIaasStorageProject_Details;

export {
  css ,
  make ,
}
/* css Not a pure module */
