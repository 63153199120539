// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              connectivityMethod: field.required("connectivityMethod", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              diskType: field.required("diskType", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              getRequestsCount: field.required("getRequestsCount", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              getRequestsUnit: field.required("getRequestsUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              putRequestsCount: field.required("putRequestsCount", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              putRequestsUnit: field.required("putRequestsUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              rawStorageCapacity: field.required("rawStorageCapacity", Json_Decode$JsonCombinators.string),
              region: field.required("region", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              transferOut: field.required("transferOut", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              transferOutUnit: field.required("transferOutUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              types: field.required("type", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
