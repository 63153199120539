// extracted by mini-css-extract-plugin
export var additionalNotes = "UserCloudIaasStorageProject_Details_RequiredProducts__additionalNotes__bOGc5";
export var checkboxes = "UserCloudIaasStorageProject_Details_RequiredProducts__checkboxes__kxbgH";
export var checkboxesContainer = "UserCloudIaasStorageProject_Details_RequiredProducts__checkboxesContainer__BGrTo";
export var column = "UserCloudIaasStorageProject_Details_RequiredProducts__column__MPJmZ";
export var description = "UserCloudIaasStorageProject_Details_RequiredProducts__description__KtVmy";
export var descriptionHeading = "UserCloudIaasStorageProject_Details_RequiredProducts__descriptionHeading___rqnf";
export var flex = "UserCloudIaasStorageProject_Details_RequiredProducts__flex__wHr4X";
export var flexColumn = "UserCloudIaasStorageProject_Details_RequiredProducts__flexColumn__fDTT1";
export var gap1 = "UserCloudIaasStorageProject_Details_RequiredProducts__gap1__kZHR6";
export var gap2 = "UserCloudIaasStorageProject_Details_RequiredProducts__gap2__TbbM3";
export var gap3 = "UserCloudIaasStorageProject_Details_RequiredProducts__gap3__A3YvC";
export var gap4 = "UserCloudIaasStorageProject_Details_RequiredProducts__gap4__ddf4i";
export var gap5 = "UserCloudIaasStorageProject_Details_RequiredProducts__gap5__BkG9R";
export var lightBackground = "UserCloudIaasStorageProject_Details_RequiredProducts__lightBackground__ak7UO";
export var notesContainer = "UserCloudIaasStorageProject_Details_RequiredProducts__notesContainer__D6B8b";
export var panel = "UserCloudIaasStorageProject_Details_RequiredProducts__panel__RpFds";
export var panelBody = "UserCloudIaasStorageProject_Details_RequiredProducts__panelBody__SipY2";
export var panelHeading = "UserCloudIaasStorageProject_Details_RequiredProducts__panelHeading__GhYxj";
export var productName = "UserCloudIaasStorageProject_Details_RequiredProducts__productName__MWNlo";
export var productRow = "UserCloudIaasStorageProject_Details_RequiredProducts__productRow__Vzs7X";
export var productServiceLabel = "UserCloudIaasStorageProject_Details_RequiredProducts__productServiceLabel__PXLWv";
export var productServiceValue = "UserCloudIaasStorageProject_Details_RequiredProducts__productServiceValue__EICSX";
export var productsServiceWrapper = "UserCloudIaasStorageProject_Details_RequiredProducts__productsServiceWrapper__kKKkz";
export var propertiesContainer = "UserCloudIaasStorageProject_Details_RequiredProducts__propertiesContainer__cCy2l";
export var propertyLeft = "UserCloudIaasStorageProject_Details_RequiredProducts__propertyLeft__jlSqg";
export var propertyRight = "UserCloudIaasStorageProject_Details_RequiredProducts__propertyRight__p1jlM";
export var row = "UserCloudIaasStorageProject_Details_RequiredProducts__row__EFAAT";
export var stayShaded = "UserCloudIaasStorageProject_Details_RequiredProducts__stayShaded__FmasV";
export var switchLight = "UserCloudIaasStorageProject_Details_RequiredProducts__switchLight__tftJP";
export var switchShaded = "UserCloudIaasStorageProject_Details_RequiredProducts__switchShaded__NcAIW";
export var tickmarkIcon = "UserCloudIaasStorageProject_Details_RequiredProducts__tickmarkIcon__LjgMX";
export var withColon = "UserCloudIaasStorageProject_Details_RequiredProducts__withColon__H9wU3";